import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../locationProvider/locationHOC';
import Table from '../general/Table';
import Tile from '../general/Tile';
import TileAttribute from '../general/TileAttribute';
import DateTime from '../general/DateTime';
import {navigateToParametrized, getQueryParam} from '../../lib/url';
import {numberOrNull} from '../../lib/number';
import {QUERY_PARAMS} from '../../constants/navigation';
import {EventObj, EventObjs} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import CustomerLink from '../general/CustomerLink';
import UserLink from '../general/UserLink';
import { Button } from 'antd';
import { formatQuantity, formatUnitPrice } from '../../lib/formatting';
import EventRemove from './EventRemove';
import EventEdit from './EventEdit';
import PdfLink from '../project/PdfLink';
import { formatTimeHTML } from '../../lib/date';
import EventSend from './EventSend';
import Price from '../general/Price';
import EventCopy from './EventCopy';
import AttachmentsList from '../project/attachments/AttachmentsList';
import UnitPrice from '../general/UnitPrice';

/**
 * @dusan
 */

class EventsTable extends React.PureComponent {
    static propTypes = {
        events: EventObjs.isRequired,
        reload: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.EVENT_ORDER_BY]: newOrder,
            [QUERY_PARAMS.EVENT_SCROLL]: 0,
        });
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.EVENT_LIMIT]: newLimit,
        });
    };

    render() {
        const {location, reload, events} = this.props;
        const orderBy = getQueryParam(location, QUERY_PARAMS.EVENT_ORDER_BY);
        const currentLimit = numberOrNull(getQueryParam(location, QUERY_PARAMS.EVENT_LIMIT));
        return <Table
            className="events-table"
            BodyRow={EventsTableRow}
            BodyTile={EventTile}
            data={events}
            limit={currentLimit}
            pageSize={!Number.isNaN(Number(process.env.DEFAULT_TABLE_PAGE_SIZE)) ? Number(process.env.DEFAULT_TABLE_PAGE_SIZE) : 20}
            changeLimit={this.setLimit}
            orderBy={orderBy}
            changeOrder={this.setOrdering}
            reload={reload}
            noTBody={true}
            colDefs={[
                {
                    class: 'events-table-col-arrow',
                },
                {
                    headerCell: <Trans>Dátum a čas</Trans>,
                    orderCol: 'start_at',
                    class: 'events-table-col-date',
                },
                {
                    headerCell: <Trans>Typ</Trans>,
                    orderCol: 'type',
                    class: 'events-table-col-type',
                },
                {
                    headerCell: <Trans>Názov</Trans>,
                    orderCol: 'title',
                    class: 'events-table-col-title',
                },
                {
                    headerCell: <Trans>Organizácia</Trans>,
                    orderCol: 'customer',
                    class: 'events-table-col-customer',
                },
                {
                    headerCell: <Trans>Účastníci</Trans>,
                    orderCol: 'user',
                    class: 'events-table-col-participants'
                },
                {
                    headerCell: <Trans>Vozidlo</Trans>,
                    orderCol: 'vehicle',
                    class: 'events-table-col-vehicle'
                },
                {
                    headerCell: <Trans>Tankovanie</Trans>,
                    orderCol: 'refuel_at',
                    class: 'events-table-col-refuel'
                },
                {
                    class: 'events-table-col-actions',
                },
            ]}
        />;
    }

}


class EventsTableRow extends React.PureComponent {
    static propTypes = {
        data: EventObj.isRequired,
        reload: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            opened: false,
        }
    }

    componentDidMount() {
        this.setState({
            opened: false,
        });
    }

    componentDidUpdate(prevProps) {
        const {data: prevData} = prevProps;
        const {id: prevEventId} = prevData;
        const {data} = this.props;
        const {id: eventId} = data;

        if(eventId != prevEventId) {
            this.setState({
                opened: false,
            });
        }
    }

    toggleOpened = () => {
        const {opened} = this.state;
        const newOpened = !opened;
        this.setState({opened: newOpened});
    }

    render() {
        const {data, reload} = this.props;
        const {opened} = this.state;
        const address = data.address;
        return <tbody>
        <tr>
            <td rowSpan={opened ? 2 : 1}>
                <Button
                    size="small"
                    onClick={this.toggleOpened}
                    icon={opened ? 'down' : 'right'}
                />
            </td>
            <td>
                <div>
                    <span className="mr-2"><Trans>od</Trans></span>
                    <DateTime timeString={data.start_at}/>
                </div>
                <div>
                    <span className="mr-2"><Trans>do</Trans></span>
                    <DateTime timeString={data.end_at}/>
                </div>
            </td>
            <td>{data.type}</td>
            <td>{data.title}</td>
            <td>
                {data.id_customer != null ?
                    <CustomerLink
                        customerId={data.id_customer}
                        customerName={data.customer}
                    /> : null
                }
            </td>
            <td rowSpan={opened ? 2 : 1}>
                { data.participants != null && Array.isArray(data.participants) ? 
                    data.participants.map((part) => {
                        return <div key={part.id_user}>
                            <UserLink 
                                userId={part.id_user}
                                userName={part.name + ' ' + part.surname + ' (' + part.customer + ')'}
                            />
                        </div>
                    }) : 
                    null
                }
            </td>
            <td>
                <div>{data.vehicle}</div>
                <div className="font-italic">{data.vehicle_description}</div>
            </td>
            <td className="text-right">
                { data.refuel_quantity != null ? 
                    <React.Fragment>
                        <div>{formatQuantity(data.refuel_quantity, 'L')}</div>
                        <div>
                            <Price price={data.refuel_price_brutto} withVAT={true}/>
                        </div>
                    </React.Fragment> : null
                }  
            </td>
            <td rowSpan={opened ? 2 : 1}>
                {data.is_away ? 
                    <PdfLink
                        objectType="event"
                        objectId={data.id}
                        size="small"
                    /> : null
                }
                <EventSend
                    eventObj={data}
                    reload={reload}
                />
                <EventEdit
                    eventObj={data}
                    reload={reload}
                />
                <EventCopy
                    eventObj={data}
                    reload={reload}
                />
                <EventRemove
                    eventObj={data}
                    reload={reload}
                />
            </td>
        </tr>
        {opened ? 
            <tr className="table-subdata">
                <td colSpan={4}>
                    <div>
                        <span className="mr-2"><Trans>Trvanie:</Trans></span>
                        {formatTimeHTML(data.duration)}
                        <span className="ml-3 mr-2"><Trans>Miesto:</Trans></span>
                        {data.place}
                        {address != null ? `${data.place != null ? ' | ' : ''}${address.street}, ${address.zip_code} ${address.city}, ${address.country}` : ''}
                    </div>
                    <div>
                        <span className="mr-2"><Trans>Popis:</Trans></span>
                        {data.description}
                    </div>
                    <div>
                        <span className="mr-2"><Trans>Prílohy:</Trans></span>
                        <AttachmentsList
                            className="ml-2 d-flex flex-wrap"
                            attachments={data.attachments}
                            reloadAttachments={reload}
                            allowEdit={true}
                            eventId={data.id}
                        />
                    </div>
                </td>
                <td>
                    { data.id_vehicle != null ? 
                        <React.Fragment>
                            <div className={data.is_km_fixed ? 'font-weight-bold' : ''}>
                                <span className="mr-2"><Trans>Koncový stav:</Trans></span>
                                {formatQuantity(data.end_km, 'km')}
                            </div>
                            <div className={data.is_fuel_fixed ? 'font-weight-bold' : ''}>
                                <span className="mr-2"><Trans>Nádrž:</Trans></span>
                                {formatQuantity(data.end_fuel_quantity, 'L') + ' /'}
                                <Price price={data.end_fuel_value} className="mx-2" withVAT={false}/>
                            </div>
                            <div className={data.is_distance_fixed ? 'font-weight-bold' : ''}>
                                <span className="mr-2"><Trans>Vzdialenosť:</Trans></span>
                                {formatQuantity(data.distance, 'km')}
                            </div>
                            <div>
                                <span className="mr-2"><Trans>Spotreba:</Trans></span>
                                {formatQuantity(data.fuel_consumption, 'L / 100 km')}
                            </div>
                        </React.Fragment>
                        : null
                    }
                </td>
                <td>
                    { data.refuel_quantity != null ? 
                        <React.Fragment>
                            <div>{data.refuel_place}</div>
                            <div>
                                <DateTime timeString={data.refuel_at}/>
                            </div>
                            <div>
                                <UnitPrice 
                                    price={data.refuel_unit_price_brutto} 
                                    unit="L"
                                    currency={data.id_currency}
                                    withVAT={true}
                                />                                    
                            </div>
                        </React.Fragment>
                        : null
                    }
                </td>
            </tr> : 
            null
        }
        </tbody>;
    }
}

class EventTile extends React.PureComponent {
    static propTypes = {
        data: EventObj.isRequired,
        reload: PropTypes.func.isRequired,
    };

    render() {
        const {data, reload} = this.props;
        return <Tile className="p-2">
                <TileAttribute
                    title={<Trans>Typ</Trans>}
                    value={data.type}
                />
                <TileAttribute
                    title={<Trans>Začiatok</Trans>}
                    value={<DateTime timeString={data.start_at}/>}
                />
                <TileAttribute
                    title={<Trans>Koniec</Trans>}
                    value={<DateTime timeString={data.end_at}/>}
                />
                <TileAttribute
                    title={<Trans>Trvanie</Trans>}
                    value={data.duration}
                />
                <TileAttribute
                    title={<Trans>Názov</Trans>}
                    value={data.title}
                />
                <TileAttribute
                    title={<Trans>Organizácia</Trans>}
                    value={ data.id_customer != null ?
                        <CustomerLink
                            customerId={data.id_customer}
                            customerName={data.customer}
                        /> : null
                    }
                />
                <TileAttribute
                    title={<Trans>Účastníci</Trans>}
                    value={ data.participants != null && Array.isArray(data.participants) ? 
                        data.participants.map((part) => {
                            return <div key={part.id_user}>
                                <UserLink 
                                    userId={part.id_user}
                                    userName={part.name + ' ' + part.surname + ' (' + part.customer + ')'}
                                />
                            </div>
                        }) : 
                        null
                    }
                />
                <TileAttribute
                    title={<Trans>Popis</Trans>}
                    value={data.description}
                />
                <TileAttribute
                    title={<Trans>Miesto</Trans>}
                    value={data.place}
                />
                {data.vehicle != null ? 
                    <React.Fragment>
                        <TileAttribute
                            title={<Trans>Vozidlo</Trans>}
                            value={data.vehicle + ' (' + data.vehicle_description + ')'}
                        />
                        <TileAttribute
                            title={<Trans>Koncový stav</Trans>}
                            value={
                                <span className={data.is_km_fixed ? 'font-weight-bold' : ''}>
                                    {formatQuantity(data.end_km, 'km')}
                                </span>
                            }
                        />
                        <TileAttribute
                            title={<Trans>Nádrž</Trans>}
                            value={
                                <span className={data.is_fuel_fixed ? 'font-weight-bold' : ''}>
                                    {formatQuantity(data.end_fuel_quantity, 'L') + ' /'}
                                    <Price price={data.end_fuel_value} className="mx-2" withVAT={false}/>
                                </span>
                            }
                        />
                        <TileAttribute
                            title={<Trans>Vzdialenosť</Trans>}
                            value={
                                <span className={data.is_distance_fixed ? 'font-weight-bold' : ''}>
                                    {formatQuantity(data.distance, 'km')}
                                </span>
                            }
                        />
                        <TileAttribute
                            title={<Trans>Tankovanie</Trans>}
                            value={ data.refuel_quantity != null ? 
                                <span>
                                    {data.refuel_place + ', '}
                                    <DateTime timeString={data.refuel_at}/>
                                    {', ' + formatUnitPrice(data.refuel_unit_price_brutto, 'L', data.id_currency)}
                                </span> :
                                null
                            }
                        />
                    </React.Fragment> : null
                }
                <div className="d-flex align-items-center">
                    <EventSend
                        eventObj={data}
                        reload={reload}
                    />
                    <EventEdit
                        eventObj={data}
                        reload={reload}
                    />
                    <EventCopy
                        eventObj={data}
                        reload={reload}
                    />
                    <EventRemove
                        eventObj={data}
                        reload={reload}
                    />
                </div>
        </Tile>;
    }
}


export default locationHOC(EventsTable);
